import { Divider, Stack } from "@mui/material";
import TeaListItem from "components/TeaListItem/TeaListItem";
import Tea from "objects/Teas/Tea";

interface ITeaListProps {
  teas: Tea[];
  isLoading: boolean;
  onRemoveItem: (tea: Tea) => void;
}

const TeaList = (props: ITeaListProps) => {
  const { teas, isLoading, onRemoveItem } = props;

  const handleRemoveItem = (tea: Tea) => {
    onRemoveItem(tea);
  };

  const renderLoading = () => (
    <div className="row">
      <div className="col-sm-12">
        <p role="status">We are loading your tea history...</p>
      </div>
    </div>
  );

  const renderEmpty = () => (
    <div className="row">
      <div className="col-sm-12">
        <p role="status">Your tea history is empty.</p>
      </div>
    </div>
  );

  const renderList = () => (
    <Stack role="list" divider={<Divider />} spacing={1}>
      {teas.map((tea) => (
        <TeaListItem key={tea.id} tea={tea} onRemove={handleRemoveItem} />
      ))}
    </Stack>
  );

  return (
    <div className="tea-list">
      {isLoading
        ? renderLoading()
        : teas.length === 0
        ? renderEmpty()
        : renderList()}
    </div>
  );
};

export default TeaList;
