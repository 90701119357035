import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import TeaList from "components/TeaList/TeaList";
import Tea from "objects/Teas/Tea";
import TeaHistoryItem from "objects/Teas/TeaHistoryItem";
import { useCallback, useEffect, useState } from "react";
import HistoryService from "services/History/HistoryService";

interface ITeaHistoryPageProps {
  historyService: HistoryService;
}

const TeaHistoryPage = (props: ITeaHistoryPageProps) => {
  const { historyService } = props;

  const [historyItems, setHistoryItems] = useState<TeaHistoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const data = await historyService.getTeaHistory(userId);

      setHistoryItems(data);
      setIsLoading(false);
      setErrors([]);
    } catch (e) {
      // console.log(e);
      setErrors(["Could not load your tea history. Please try again later..."]);
    }
  }, [historyService]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const title = "Tea History";
  const userId = 1;

  const handleRemoveItem = async (tea: Tea) => {
    try {
      const item = historyItems.find((i) => i.Tea.id === tea.id);

      if (item) {
        const isSuccess = await historyService.removeTeaFromHistory(item.id);

        if (isSuccess) {
          await fetchData();
        }
      } else {
        throw new Error(`Item for tea [id: ${tea.id} does not exist.`);
      }
    } catch (e) {
      // console.log(e);
      setErrors([
        "Could not remove the tea from your tea history. Please try again later...",
      ]);
    }
  };

  const renderErrors = () => (
    <div role="status" aria-label="errors">
      {errors.map((e, idx) => (
        <Alert key={idx} severity="error">
          <AlertTitle>Error</AlertTitle>
          {e}
        </Alert>
      ))}
    </div>
  );

  // Set HTML5 document's title. (Breaks definition of pure components...)
  document.title = `TEAfam - ${title}`;

  return (
    <Stack component="section" spacing={2}>
      <Typography variant="h1" sx={{ alignSelf: "center" }}>
        {title}
      </Typography>

      {errors.length > 0 && renderErrors()}

      <TeaList
        teas={historyItems.map((i) => i.Tea)}
        isLoading={isLoading}
        onRemoveItem={handleRemoveItem}
      />
    </Stack>
  );
};

export default TeaHistoryPage;
