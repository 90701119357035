import AddTeaHistoryItemDto from "objects/Teas/DTOs/AddTeaHistoryItemDto";
import TeaHistoryItemDto, {
  isTeaHistoryItemDto,
} from "objects/Teas/DTOs/TeaHistoryItemDto";
import Tea from "objects/Teas/Tea";
import TeaHistoryItem from "objects/Teas/TeaHistoryItem";
import HistoryService from "./HistoryService";

class ApiHistoryService implements HistoryService {
  async getTeaHistory(userId: number): Promise<TeaHistoryItem[]> {
    const url = `${process.env.REACT_APP_API_HISTORY_BASEURL}/api/v1/history/${userId}`;
    const response = await fetch(encodeURI(url));

    if (response.ok && response.status === 200) {
      // Ensure the response JSON format was valid.
      const data = await response.json();

      if (
        Array.isArray(data.items) &&
        (data.items.length === 0 || data.items.some(isTeaHistoryItemDto))
      ) {
        // Map DTO to model.
        return data.items.map(
          (x: TeaHistoryItemDto) =>
            ({
              id: x.id,
              Tea: { id: x.teaId, name: x.teaName },
            } as TeaHistoryItem)
        );
      } else {
        throw new TypeError("API response has an invalid format.");
      }
    } else if (response.status === 404) {
      return [] as TeaHistoryItem[];
    } else {
      throw new Error("Unexpected error occured during API call.");
    }
  }

  async addTeaInHistory(userId: number, tea: Tea): Promise<boolean> {
    const url = `${process.env.REACT_APP_API_HISTORY_BASEURL}/api/v1/history/items`;
    const response = await fetch(encodeURI(url), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        teaId: tea.id,
        teaName: tea.name,
      } as AddTeaHistoryItemDto),
    });

    if (response.ok && response.status === 200) {
      // Ensure the response JSON format was valid.
      const data = await response.json();

      if (
        Array.isArray(data.items) &&
        (data.items.length === 0 || data.items.some(isTeaHistoryItemDto))
      ) {
        // // Map DTO to model.
        // return data.items.map(
        //   (x: TeaHistoryItemDto) =>
        //     ({
        //       id: x.id,
        //       Tea: { id: x.teaId, name: x.teaName },
        //     } as TeaHistoryItem)
        // );
        return true;
      } else {
        throw new TypeError("API response has an invalid format.");
      }
    } else {
      throw new Error("Unexpected error occured during API call.");
    }
  }

  async removeTeaFromHistory(historyItemId: number): Promise<boolean> {
    const url = `${process.env.REACT_APP_API_HISTORY_BASEURL}/api/v1/history/items/${historyItemId}`;
    const response = await fetch(encodeURI(url), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok && response.status === 200) {
      // Ensure the response JSON format was valid.
      const data = await response.json();

      if (data !== null) {
        return true;
      } else {
        throw new TypeError("API response has an invalid format.");
      }
    } else if (response.status === 404) {
      return false;
    } else {
      throw new Error("Unexpected error occured during API call.");
    }
  }
}

export default ApiHistoryService;
