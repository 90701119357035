export default interface SearchResultDto {
  id: number;
  name: string;
  description: string;
  // imageUrl?: string;
  isInTeaHistory: boolean;
}

export const isSearchResultDto = (x: SearchResultDto): x is SearchResultDto => {
  return (
    x.id !== undefined &&
    x.name !== undefined &&
    x.description !== undefined &&
    // x.imageUrl !== undefined &&
    x.isInTeaHistory !== undefined
  );
};
