import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import TeaFinderCriteria from "objects/Teas/TeaFinderCriteria";
import { SyntheticEvent, useState } from "react";

interface ITeaFinderFormProps {
  onSearch: (criteria: TeaFinderCriteria) => void;
}

const TeaFinderForm = (props: ITeaFinderFormProps) => {
  const { onSearch } = props;

  const [criteria, setCriteria] = useState(
    new TeaFinderCriteria("", "name", "asc", "none")
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = e.target;

    // Determine whether to use 'checked' or 'value'.
    let updatedValue: any = type === "checkbox" ? checked : value;

    // Convert numbers to 'number' instead of string.
    if (type === "number") {
      updatedValue = Number(updatedValue);
    }

    const change = { [name]: updatedValue };

    setCriteria((c) => {
      // Create a new instance of TeaFinderCriteria class since we will use
      // its 'toQueryString()' method.
      const { query, sortBy, sortOrder, groupBy } = { ...c, ...change };
      const updated = new TeaFinderCriteria(query, sortBy, sortOrder, groupBy);
      return updated;
    });
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    onSearch(criteria);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        component="form"
        role="search"
        aria-label="Search for teas, stores or suppliers"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="query"
              name="query"
              label="Search"
              placeholder="Search for teas, stores or suppliers"
              type="search"
              fullWidth
              value={criteria.query}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl>
              <FormLabel id="sort-by-label">Sort By</FormLabel>
              <RadioGroup
                aria-labelledby="sort-by-label"
                name="sortBy"
                row
                value={criteria.sortBy}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="name"
                  control={<Radio />}
                  label="Name"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl>
              <FormLabel id="sort-order-label">Sort Order</FormLabel>
              <RadioGroup
                aria-labelledby="sort-order-label"
                name="sortOrder"
                row
                value={criteria.sortOrder}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="asc"
                  control={<Radio />}
                  label="Ascending"
                />
                <FormControlLabel
                  value="desc"
                  control={<Radio />}
                  label="Descending"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <FormLabel id="group-by-label">Group By</FormLabel>
              <RadioGroup
                aria-labelledby="group-by-label"
                name="groupBy"
                row
                value={criteria.groupBy}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <Button type="submit" variant="contained">
            Search
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default TeaFinderForm;
