import { Avatar, Button, Grid, Stack, Typography } from "@mui/material";
import Tea from "objects/Teas/Tea";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const splits = name.split(" ");

  return {
    sx: {
      width: 75,
      height: 75,
      bgcolor: stringToColor(name),
    },
    children: `${splits[0][0]}${
      splits.length > 1 ? splits[1][0] : splits[0][1] ?? ""
    }`,
    alt: name,
  };
}

interface ITeaListItemProps {
  tea: Tea;
  onRemove: (tea: Tea) => void;
}

const TeaListItem = (props: ITeaListItemProps) => {
  const { tea, onRemove } = props;

  return (
    <Grid role="listitem" container alignItems="center" justifyContent="center">
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        {tea.imageUrl ? (
          <Avatar
            sx={{ width: 75, height: 75 }}
            alt={tea.name}
            src={tea.imageUrl}
          />
        ) : (
          <Avatar {...stringAvatar(tea.name)} />
        )}
      </Grid>
      <Grid item xs={7} paddingX={1}>
        <Stack direction="column">
          <Typography variant="h6" component="span" fontWeight="bold">
            {tea.name}
          </Typography>
          <Typography variant="body1">{tea.description}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} style={{ display: "flex", justifyContent: "center" }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          <Button variant="contained">View</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => onRemove(tea)}
          >
            Remove
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TeaListItem;
