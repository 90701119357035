import SearchResultDto, {
  isSearchResultDto,
} from "objects/Teas/DTOs/SearchResultDto";
import SearchResult from "objects/Teas/SearchResult";
import Tea from "objects/Teas/Tea";
import TeaFinderCriteria from "objects/Teas/TeaFinderCriteria";
import CatalogService from "./CatalogService";

class ApiCatalogService implements CatalogService {
  getTeas(): Promise<Tea[]> {
    throw new Error("Method not implemented.");
  }

  addTea(tea: Tea): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  editTea(tea: Tea): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  async search(
    userId: number,
    criteria: TeaFinderCriteria
  ): Promise<SearchResult[]> {
    const qs = criteria.toQueryString?.();
    const url = `${
      process.env.REACT_APP_API_CATALOG_BASEURL
    }/api/v1/agg/catalog/teas?userId=${userId}${qs.length > 0 ? `&${qs}` : ""}`;
    const response = await fetch(encodeURI(url));

    if (response.ok && response.status === 200) {
      // Ensure the response JSON format was valid.
      const data = await response.json();

      if (
        Array.isArray(data) &&
        (data.length === 0 || data.some(isSearchResultDto))
      ) {
        // Map DTO to model.
        return data.map(
          (x: SearchResultDto) =>
            ({
              id: x.id,
              name: x.name,
              description: x.description,
              // imageUrl: x.imageUrl,
              isInTeaHistory: x.isInTeaHistory,
            } as SearchResult)
        );
      } else {
        throw new TypeError("API response has an invalid format.");
      }
    } else {
      throw new Error("Unexpected error occured during API call.");
    }
  }

  // async search(criteria: TeaFinderCriteria): Promise<Tea[]> {
  //   const qs = criteria.toQueryString?.();
  //   const url = `${
  //     process.env.REACT_APP_API_CATALOG_BASEURL
  //   }/api/v1/catalog/teas${qs.length > 0 ? `?${qs}` : ""}`;
  //   const response = await fetch(encodeURI(url));

  //   if (response.ok && response.status === 200) {
  //     // Ensure the response JSON format was valid.
  //     const data = await response.json();

  //     if (
  //       Array.isArray(data) &&
  //       (data.length === 0 || data.some(isSearchResultDto))
  //     ) {
  //       // Map DTO to model.
  //       return data.map(
  //         (x: SearchResultDto) =>
  //           ({
  //             id: x.id,
  //             name: x.name,
  //             description: x.description,
  //             // imageUrl: x.imageUrl,
  //           } as Tea)
  //       );
  //     } else {
  //       throw new TypeError("API response has an invalid format.");
  //     }
  //   } else {
  //     throw new Error("Unexpected error occured during API call.");
  //   }
  // }
}

export default ApiCatalogService;
