export default interface TeaHistoryItemDto {
  id: number;
  teaId: number;
  teaName: string;
  dateAdded: Date;
}

export const isTeaHistoryItemDto = (
  x: TeaHistoryItemDto
): x is TeaHistoryItemDto => {
  return (
    x.id !== undefined &&
    x.teaId !== undefined &&
    x.teaName !== undefined &&
    x.dateAdded !== undefined
  );
};
