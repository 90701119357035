import App from "components/App/App";
import { Feature } from "flagged";
import Header from "layout/Header/Header";
import { createRoutesFromElements, Route } from "react-router-dom";
import CatalogService from "services/Catalog/CatalogService";
import HistoryService from "services/History/HistoryService";
import RootErrorPage from "./RootErrorPage/RootErrorPage";
import TeaFinderPage from "./TeaFinderPage/TeaFinderPage";
import TeaHistoryPage from "./TeaHistoryPage/TeaHistoryPage";

const createAppRoutes = (
  catalogService: CatalogService,
  historyService: HistoryService,
  {
    app = <App />,
    // indexPage = <TeaCollectionPage catalogService={catalogService} />,
    indexPage = <TeaHistoryPage historyService={historyService} />,
    historyPage = <TeaHistoryPage historyService={historyService} />,
    finderPage = (
      <TeaFinderPage
        catalogService={catalogService}
        historyService={historyService}
      />
    ),
    registerPage = <Header />,
    loginPage = <Header />,
  } = {}
) => {
  const routes = createRoutesFromElements(
    <Route path="/" element={app} errorElement={<RootErrorPage />}>
      <Route index element={indexPage} />
      <Route path="history" element={historyPage} />
      <Route path="finder" element={finderPage} />
      <Route
        path="register"
        element={
          <Feature name="accounts">
            {(isEnabled) => {
              if (isEnabled) return registerPage;
              else throw new Error();
            }}
          </Feature>
        }
      />
      <Route
        path="login"
        element={
          <Feature name="accounts">
            {(isEnabled) => {
              if (isEnabled) return loginPage;
              else throw new Error();
            }}
          </Feature>
        }
      />
    </Route>
  );

  return routes;
};

export default createAppRoutes;
