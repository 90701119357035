import { Box, Button, Stack, Typography } from "@mui/material";
import SearchResult from "objects/Teas/SearchResult";
import Tea from "objects/Teas/Tea";

interface ITeaFinderResultsProps {
  result: SearchResult;
  onAdd: (tea: Tea) => void;
}

const TeaFinderResults = (props: ITeaFinderResultsProps) => {
  const { result, onAdd } = props;

  const handleAdd = () => {
    const { isInTeaHistory, ...tea } = result;
    onAdd(tea);
  };

  return (
    <Stack role="listitem" direction="row" spacing={2} alignItems="center">
      <Stack width="100%">
        <Typography variant="h6" component="span">
          {result.name}
        </Typography>
        <Typography variant="body1" component="span">
          {result.description}
        </Typography>
      </Stack>
      <Box>
        {!result.isInTeaHistory && (
          <Button variant="contained" onClick={handleAdd}>
            Add
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default TeaFinderResults;
