import { Alert, AlertTitle, Divider, Stack, Typography } from "@mui/material";
import TeaFinderForm from "components/TeaFinderForm/TeaFinderForm";
import TeaFinderResults from "components/TeaFinderResults/TeaFinderResults";
import SearchResult from "objects/Teas/SearchResult";
import Tea from "objects/Teas/Tea";
import TeaFinderCriteria from "objects/Teas/TeaFinderCriteria";
import { useState } from "react";
import CatalogService from "services/Catalog/CatalogService";
import HistoryService from "services/History/HistoryService";

interface ITeaFinderPageProps {
  catalogService: CatalogService;
  historyService: HistoryService;
}

const TeaFinderPage = (props: ITeaFinderPageProps) => {
  const { catalogService, historyService } = props;

  const [alerts, setAlerts] = useState<string[]>([]);
  const [results, setResults] = useState<SearchResult[]>();

  const title = "Tea Finder";
  const userId = 1;

  const handleSearch = async (criteria: TeaFinderCriteria) => {
    try {
      const data = await catalogService.search(userId, criteria);
      setResults(data);
      setAlerts([]);
    } catch (e) {
      setAlerts([
        "Could not complete your search at this time. Please try again later...",
      ]);
    }
  };

  const handleAdd = async (tea: Tea) => {
    try {
      const isSuccess = await historyService.addTeaInHistory(userId, tea);

      if (isSuccess) {
        setResults(
          results?.map((r) =>
            r.id === tea.id ? { ...tea, isInTeaHistory: true } : r
          )
        );
        setAlerts([]);
      }
    } catch (e) {
      setAlerts([
        "Could not add this tea to your history at this time. Please try again later...",
      ]);
    }
  };

  const renderAlerts = () => (
    <Stack role="status" aria-label="errors" spacing={2}>
      {alerts.map((alert, idx) => (
        <Alert key={idx} severity="error">
          <AlertTitle>Error</AlertTitle>
          {alert}
        </Alert>
      ))}
    </Stack>
  );

  const renderResults = () =>
    results && (
      <section aria-labelledby="search-results">
        <h2 id="search-results">Search Results</h2>

        {results.length > 0 ? (
          <Stack role="list" spacing={2} divider={<Divider />}>
            {results.map((result) => (
              <TeaFinderResults
                key={result.id}
                result={result}
                onAdd={handleAdd}
              />
            ))}
          </Stack>
        ) : (
          <p role="status" aria-label="search results status">
            Could not find any teas matching your criteria. Please try another
            search using different criteria.
          </p>
        )}
      </section>
    );

  // Set HTML5 document's title. (Breaks definition of pure components...)
  document.title = `TEAfam - ${title}`;

  return (
    <Stack component="section" spacing={2}>
      <Typography variant="h1" sx={{ alignSelf: "center" }}>
        {title}
      </Typography>

      {alerts.length > 0 && renderAlerts()}

      <TeaFinderForm onSearch={handleSearch} />

      {renderResults()}
    </Stack>
  );
};

export default TeaFinderPage;
