type SortByOptions = "name";
type SortOrderOptions = "desc" | "asc";
type GroupByOptions = "none";

export default class TeaFinderCriteria {
  query: string;
  sortBy: SortByOptions;
  sortOrder: SortOrderOptions;
  groupBy: GroupByOptions;

  constructor(
    query: string,
    sortBy: SortByOptions,
    sortOrder: SortOrderOptions,
    groupBy: GroupByOptions
  ) {
    this.query = query;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.groupBy = groupBy;
  }

  toQueryString: () => string = () => {
    let qs: string[] = [];

    if (this.query) {
      qs.push(`query=${encodeURIComponent(this.query)}`);
    }
    if (this.sortBy) {
      qs.push(`sort=${this.sortBy}_${this.sortOrder}`);
    }

    const result = qs.join("&");
    return result.length > 0 ? `${result}` : "";
  };
}
